<template>
  <div class="require-auth-component">
    <div v-if="showTitle" class="title-block">
      <slot name="title">
        <h3>
          {{tu('user.requireAuthComponent.title')}}
        </h3>
      </slot>
    </div>
    <div v-if="showExplainText" class="explain-text-block">
      <slot name="before-cta">
        <p>
          {{ tu("user.requireAuthComponent.explain") }}
        </p>
      </slot>
    </div>

    <div class="cta-block">
      <slot name="cta" v-bind="{redirectToLogin,redirectToRegister}">
        <div class="flex-start margin-2xl-top">
          <div class="flex flex-middle flex-center gap-l">
            <form-button
              icon="arrow-login-inline-end"
              text="user.requireAuthComponent.loginCta"
              @click="redirectToLogin()"></form-button>
            <span>{{ tu("user.requireAuthComponent.or") }}</span>
            <form-button
              icon="user"
              text="user.requireAuthComponent.registerCta"
              @click="redirectToRegister()"></form-button>
          </div>
        </div>
      </slot>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    redirect: { // goes to login view. Can be relative URL or route name
      type: [String, Boolean],
      default: false,
    },
    redirectParams: {
      type: Object,
      default: () => {},
    },
    loginRouteName: {
      type: String,
      default: "user-login",
    },
    registerRouteName: {
      type: String,
      default: "user-login",
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showExplainText: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {}
  },
  methods: {
    redirectToLogin() {
      this.$router.push({
        name: this.loginRouteName,
        query: {
          redirect: this.redirect,
          redirectParams: this.redirectParams,
        },
      });
    },
    redirectToRegister() {
      this.$router.push({
        name: this.registerRouteName,
        query: {
          redirect: this.redirect,
          redirectParams: this.redirectParams,
        },
      });
    },
  }

}
</script>

<style scoped lang="scss">

</style>
